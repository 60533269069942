import { Domain } from "src/types";
import { $post, throwHttpRequestError } from "../http";
import { UploadPrefix } from "./types";
const uploadFile = async (file, prefix = UploadPrefix.Image) => {
    try {
        const formData = new FormData();
        formData.append("file", file);
        const response = await $post(`/v1/cm/${prefix}`, formData, Domain.Upload, {
            transformRequest: [(data) => data],
        });
        if (!response.data) {
            return null;
        }
        return response.data.url;
    }
    catch (err) {
        console.error("uploadFile", err);
        throwHttpRequestError(err);
    }
};
export { uploadFile };
