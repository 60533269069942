import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from "react";
import { Button, Image, Menu, Dropdown, Typography, Col } from "antd";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { useSelector } from "react-redux";
import { MESSAGE_BOX_TYPE, SAColourScheme } from "src/constants";
import { archiveSupplier, deleteSupplier, getArchivedSupplier, getInventoryDetails, getSupplierContactDetails, restoreSupplier, } from "src/api/inventory/inventoryService";
import { checkAuth } from "src/api/auth/login";
import ContentContext from "src/providers/content";
import { LocationInventory } from "./components/LocationInventory/LocationInventory";
import { SiteContracts } from "./components/SiteContacts/SiteContacts";
import { StockRequirements } from "./components/StockRequirements/StockRequirements";
import { ContactDetails } from "./components/SupplierContactDetails/ContactDetails";
import ICSubmenuItem from "/public/assets/svg/inventory/ic-item-submenu.svg";
import { Archieve } from "./components/Archieve";
import { DropDownWrapper } from "./index.style";
const { Text } = Typography;
const SortableItem = SortableElement(({ value }) => (_jsxs("div", { style: {
        display: "flex",
        cursor: "grab",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "5px",
        border: "1px solid #ADB3BC",
        marginBottom: "10px",
        borderRadius: "5px",
        background: "white",
        zIndex: 10,
    }, children: [_jsx("div", { style: { fontSize: "12px" }, children: value }), _jsx(ICSubmenuItem, {})] })));
const SortableList = SortableContainer(({ items }) => (_jsx("div", { style: { padding: "5px 10px", zIndex: 1 }, children: items.map((value, index) => (_jsx(SortableItem, { index: index, value: value }, `item-${index}`))) })));
const DropDown = ({ components, defaultOrder, onOrderChange }) => {
    const [visible, setVisible] = useState(false);
    const [orderedComponents, setOrderedComponents] = useState(components);
    const onSortEnd = ({ oldIndex, newIndex }) => {
        setOrderedComponents(arrayMove(orderedComponents, oldIndex, newIndex));
    };
    const handleOk = () => {
        onOrderChange(orderedComponents);
        setVisible(false);
    };
    const handleDefault = () => {
        setOrderedComponents(defaultOrder);
    };
    const menu = (_jsxs(Menu, { style: { border: "5px solid black", padding: "10px" }, children: [_jsxs("div", { style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    marginBottom: "5px",
                }, children: [_jsx(Text, { style: { fontSize: "10px", color: "#7F8E9D", marginBottom: "5px" }, children: "Drag & Drop to Reorder" }), _jsx(Button, { onClick: handleDefault, style: {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "#EBEDF0",
                            color: "#2F3337",
                            borderRadius: "13px",
                            border: "none",
                            padding: "5px",
                            fontSize: "10px",
                            width: "50px",
                            height: "20px",
                        }, children: "Default" })] }), _jsx(SortableList, { items: orderedComponents.map((item) => item.name), onSortEnd: onSortEnd, helperClass: "dragged-item" }), _jsx(Button, { type: "primary", onClick: handleOk, style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#1A8CFF",
                    border: "none",
                    borderRadius: "5px",
                    width: "80%",
                    margin: "auto",
                    height: "25px",
                }, children: "OK" })] }));
    return (_jsx(Col, { span: 24, style: { display: "flex", justifyContent: "flex-end" }, children: _jsx(Dropdown, { overlay: menu, trigger: ["click"], open: visible, onOpenChange: setVisible, overlayStyle: { minWidth: "350px", zIndex: 2 }, children: _jsx(Button, { style: {
                    background: "#1A8CFF",
                    color: `${SAColourScheme.WHITE}`,
                    border: "1px solid #1A8CFF",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "row-reverse",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                    height: "35px",
                    width: "200px",
                }, icon: _jsx(Image, { src: "/assets/svg/inventory/ic-submenu.png", preview: false, width: 22, height: 22, style: { marginLeft: "10px" } }), onClick: () => setVisible(!visible), children: _jsx("span", { style: { fontSize: "12px" }, children: "Reorder items" }) }) }) }));
};
export const InventorySections = () => {
    const [inventoryData, setInventoryData] = useState([]);
    const [archivedData, setArchivedData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const userInfo = useSelector(checkAuth.dataSelector);
    const { openMessageBox, openLoadingScreen, closeLoadingScreen, openToast } = useContext(ContentContext);
    const getSupplierData = async () => {
        openLoadingScreen();
        const result = await getSupplierContactDetails(userInfo.accessToken);
        if (result.status === "success") {
            setTableData(result.data);
        }
        else {
            setTableData([]);
        }
        closeLoadingScreen();
    };
    const getArchivedSupplierData = async () => {
        openLoadingScreen();
        const result = await getArchivedSupplier(userInfo.accessToken);
        if (result.status === "success") {
            setArchivedData(result.data);
        }
        else {
            setArchivedData([]);
        }
        closeLoadingScreen();
    };
    const supplierArchive = async (id) => {
        openLoadingScreen();
        const response = await archiveSupplier(userInfo.accessToken, id);
        if (response.status === "success") {
            openToast("Archived successfully");
            getSupplierData();
            getArchivedSupplierData();
        }
        else {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "Something went wrong. Please try again");
        }
        closeLoadingScreen();
    };
    // Delete functionality for each device - set isValid to false
    const supplierDelete = async (id) => {
        openLoadingScreen();
        const response = await deleteSupplier(userInfo.accessToken, id);
        if (response.status === "success") {
            openToast("Deleted successfully");
            getSupplierData();
            getArchivedSupplierData();
        }
        else {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "Something went wrong. Please try again");
        }
        closeLoadingScreen();
    };
    const supplierRestore = async (id) => {
        openLoadingScreen();
        const response = await restoreSupplier(userInfo.accessToken, id);
        if (response.status === "success") {
            openToast("Restored successfully");
            getSupplierData();
            getArchivedSupplierData();
        }
        else {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "Something went wrong. Please try again");
        }
        closeLoadingScreen();
    };
    const getInventoryData = async () => {
        openLoadingScreen();
        const result = await getInventoryDetails(userInfo.accessToken);
        if (result.status === "success") {
            setInventoryData(result.data);
        }
        else {
            setInventoryData([]);
        }
        closeLoadingScreen();
    };
    useEffect(() => {
        getInventoryData();
        getSupplierData();
        getArchivedSupplierData();
    }, [userInfo]);
    const components = [
        {
            name: "Device Inventory by Location",
            component: _jsx(LocationInventory, { inventoryData: inventoryData }, "location"),
        },
        {
            name: "Minimum Stock Requirements",
            component: _jsx(StockRequirements, { inventoryData: inventoryData }, "requirements"),
        },
        {
            name: "Site Contacts",
            component: _jsx(SiteContracts, { inventoryData: inventoryData }, "contracts"),
        },
        {
            name: "Inventory Supplier Contact Details",
            component: (_jsx(ContactDetails, { tableData: tableData, setTableData: setTableData, supplierDelete: supplierDelete, supplierArchive: supplierArchive }, "contacts")),
        },
    ];
    const defaultOrder = [
        {
            name: "Device Inventory by Location",
            component: _jsx(LocationInventory, { inventoryData: inventoryData }, "location"),
        },
        {
            name: "Minimum Stock Requirements",
            component: _jsx(StockRequirements, { inventoryData: inventoryData }, "requirements"),
        },
        {
            name: "Site Contacts",
            component: _jsx(SiteContracts, { inventoryData: inventoryData }, "contracts"),
        },
        {
            name: "Inventory Supplier Contact Details",
            component: (_jsx(ContactDetails, { tableData: tableData, setTableData: setTableData, supplierDelete: supplierDelete, supplierArchive: supplierArchive, getSupplierData: getSupplierData }, "contacts")),
        },
    ];
    const [orderedComponents, setOrderedComponents] = useState(components);
    const handleOrderChange = (newOrder) => {
        setOrderedComponents(newOrder);
    };
    return (_jsx("div", { children: _jsxs(DropDownWrapper, { children: [_jsx(DropDown, { components: orderedComponents, defaultOrder: defaultOrder, onOrderChange: handleOrderChange }), _jsx("div", { style: { borderTop: "1px solid #ADB3BC" } }), orderedComponents.map((item, index) => React.cloneElement(item.component, {
                    key: index,
                    inventoryData,
                    tableData,
                    setTableData,
                    supplierArchive,
                    supplierDelete,
                    getSupplierData,
                })), _jsx(Archieve, { data: archivedData, supplierRestore: supplierRestore, supplierDelete: supplierDelete })] }) }));
};
